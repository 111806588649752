import React, { useState, useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink, useNavigate } from 'react-router-dom';
import api, { setupInterceptors } from './api';
import Login from './components/Login';
import Selection from './components/Selection';
import Results from './components/Results';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [accountInfo, setAccountInfo] = useState(JSON.parse(localStorage.getItem('accountInfo')) || null);
  const [file, setFile] = useState(null);
  const [analysisStarted, setAnalysisStarted] = useState(false);
  const [outputPath, setOutputPath] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const navigate = useNavigate();

  const resetState = () => {
    setFile(null);
    setAnalysisStarted(false);
    setOutputPath(null);
    setCurrentStep(0);
    setIsComplete(false);
  };

  const handleLogout = useCallback(() => {
    setToken(null);
    setAccountInfo(null);
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accountInfo');
    resetState();
    navigate('/');
  }, [navigate]);

  const handleLogin = (newToken, newAccountInfo) => {
    setToken(newToken);
    setAccountInfo(newAccountInfo);
    localStorage.setItem('token', newToken);
    localStorage.setItem('accountInfo', JSON.stringify(newAccountInfo));
    resetState();
  };

  const fetchLatestAccountInfo = useCallback(async () => {
    if (token && accountInfo) {
      try {
        const response = await api.get('/api/v1/check_account_code', {
          params: { account_code: accountInfo.code },
        });
        setAccountInfo(response.data);
        localStorage.setItem('accountInfo', JSON.stringify(response.data));
      } catch (error) {
        console.error('Failed to fetch latest account info:', error);
        if (error.response && error.response.status === 401) {
          handleLogout();
        }
      }
    }
  }, [token, accountInfo, handleLogout]);

  const refreshToken = useCallback(async () => {
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        throw new Error('No refresh token available');
      }
      const response = await api.post('/api/v1/refresh', { refresh_token: refreshToken });
      const newToken = response.data.access_token;
      setToken(newToken);
      localStorage.setItem('token', newToken);
      return newToken;
    } catch (error) {
      console.error('Failed to refresh token:', error);
      handleLogout();
      return null;
    }
  }, [handleLogout]);

  useEffect(() => {
    setupInterceptors(refreshToken, handleLogout);
  }, [refreshToken, handleLogout]);

  const updateQuota = (newQuota) => {
    setAccountInfo((prevInfo) => ({
      ...prevInfo,
      total_quota: prevInfo.total_quota,
      consumed_quota: prevInfo.total_quota - newQuota
    }));
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <header className="bg-retoma-blue-dark text-white py-4">
        <div className="container mx-auto px-4 flex items-center">
          <h1 className="text-3xl font-roboto font-bold mr-8">retoma</h1>
          {token && (
            <nav className="flex-grow">
              <NavLink
                to="/selection"
                className={({ isActive }) =>
                  `mr-4 py-2 px-4 rounded ${isActive ? 'bg-retoma-blue text-white' : 'text-white hover:bg-retoma-blue-light'}`
                }
              >
                Analysis
              </NavLink>
              <NavLink
                to="/results"
                className={({ isActive }) =>
                  `mr-4 py-2 px-4 rounded ${isActive ? 'bg-retoma-blue text-white' : 'text-white hover:bg-retoma-blue-light'}`
                }
              >
                Results
              </NavLink>
            </nav>
          )}
          {token && accountInfo && (
            <div className="flex items-center">
              <span className="mr-4">
                Remaining {accountInfo.total_quota - accountInfo.consumed_quota} of {accountInfo.total_quota} papers
              </span>
              <button
                onClick={handleLogout}
                className="bg-retoma-orange hover:bg-retoma-yellow text-white font-bold py-2 px-4 rounded"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </header>
      <main className="container mx-auto px-4 py-8 flex-grow">
        <Routes>
          <Route path="/" element={token ? <Navigate to="/selection" /> : <Login onLogin={handleLogin} />} />
          <Route
            path="/selection"
            element={
              token ? (
                <Selection
                  token={token}
                  file={file}
                  setFile={setFile}
                  analysisStarted={analysisStarted}
                  setAnalysisStarted={setAnalysisStarted}
                  outputPath={outputPath}
                  setOutputPath={setOutputPath}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  isComplete={isComplete}
                  setIsComplete={setIsComplete}
                  fetchLatestAccountInfo={fetchLatestAccountInfo}
                />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/results"
            element={token ? <Results updateQuota={updateQuota} /> : <Navigate to="/" />}
          />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;