import React, { useState } from 'react';
import api from '../api';
import ConsentDialog from './ConsentDialog';

const Login = ({ onLogin }) => {
  const [accountCode, setAccountCode] = useState('');
  const [error, setError] = useState('');
  const [showConsent, setShowConsent] = useState(false);
  const [token, setToken] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      console.log('Attempting to get token for:', accountCode);
      const tokenResponse = await api.post('/api/v1/token', null, {
        params: { username: accountCode },
      });
      console.log('Token response:', tokenResponse.data);
      const { access_token, refresh_token, consent_required } = tokenResponse.data;
      localStorage.setItem('token', access_token);
      localStorage.setItem('refreshToken', refresh_token);
      setToken(access_token);

      if (consent_required) {
        console.log('Consent required. Showing consent dialog.');
        setShowConsent(true);
      } else {
        console.log('Consent not required. Proceeding with login.');
        await completeLogin(access_token);
      }
    } catch (loginError) {
      console.error('Login failed:', loginError.response || loginError);
      setError('Invalid username or password. Please try again.');
    }
  };

  const completeLogin = async (accessToken) => {
    try {
      console.log('Checking account code with token');
      const accountInfoResponse = await api.get('/api/v1/check_account_code', {
        params: { account_code: accountCode },
      });
      console.log('Account info response:', accountInfoResponse.data);

      onLogin(accessToken, accountInfoResponse.data);
    } catch (accountError) {
      console.error('Account check failed:', accountError.response || accountError);
      if (accountError.response && accountError.response.status === 404) {
        setError('Account not found. Please check your account code.');
      } else {
        setError('Error verifying account. Please try again.');
      }
    }
  };

  const handleConsent = async () => {
    try {
      console.log('Creating consent with token');
      await api.post('/api/v1/create_consent');
      console.log('Consent created successfully');
      setShowConsent(false);
      await completeLogin(token);
    } catch (error) {
      console.error('Consent creation failed:', error);
      setError('Failed to create consent. Please try again.');
    }
  };

  const handleCloseConsent = () => {
    setShowConsent(false);
    setToken('');
    setAccountCode('');
  };

  return (
    <div className="max-w-md mx-auto">
      <h2 className="text-2xl font-bold mb-4">Login</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={accountCode}
          onChange={(e) => setAccountCode(e.target.value)}
          placeholder="Enter your unique account code"
          className="w-full p-2 mb-4 border rounded"
          required
        />
        <button type="submit" className="w-full bg-retoma-blue hover:bg-retoma-blue-light text-white font-bold py-2 px-4 rounded">
          Login
        </button>
      </form>
      {showConsent && <ConsentDialog onConsent={handleConsent} onClose={handleCloseConsent} />}
    </div>
  );
};

export default Login;