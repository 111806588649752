const getApiBaseUrl = () => {
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  }

  const hostname = window.location.hostname;
  if (hostname === 'localhost' || hostname === '127.0.0.1') {
    return 'http://localhost:8000';
  }

  // For production domains, use the full path
  if (hostname === 'app.retoma.ai' || hostname === 'app.retoma.health') {
    return `https://${hostname}`;
  }

  // Fallback for any other unexpected hostname
  console.warn(`Unexpected hostname: ${hostname}. Using default API URL.`);
  return 'https://app.retoma.ai/api';
};

const config = {
  terms_url: "https://retoma.health/terms",
  privacy_policy_url: "https://retoma.health/privacy",
  impressum_url: "https://retoma.health/impressum",
  API_BASE_URL: getApiBaseUrl()
};

export default config;