import React, { useState, useRef, useEffect, useCallback } from 'react';
import config from '../config';

const ConsentDialog = ({ onConsent, onClose }) => {
  const [agreeToTandC, setAgreeToTandC] = useState(false);
  const dialogRef = useRef(null);

  const handleConsent = () => {
    if (agreeToTandC) {
      onConsent();
    }
  };

  const handleClickOutside = useCallback((event) => {
    if (dialogRef.current && !dialogRef.current.contains(event.target)) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
      <div ref={dialogRef} className="bg-white p-5 rounded-lg shadow-xl max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          ✕
        </button>
        <h2 className="text-xl font-bold mb-4">Consent Required</h2>
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={agreeToTandC}
              onChange={(e) => setAgreeToTandC(e.target.checked)}
              className="mr-2"
            />
            <span>
              I agree to the{' '}
              <a href={config.terms_url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
                Terms & Conditions
              </a>{' '}
              and{' '}
              <a href={config.privacy_policy_url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
                Privacy Policy
              </a>
            </span>
          </label>
        </div>
        <button
          onClick={handleConsent}
          disabled={!agreeToTandC}
          className={`w-full ${
            agreeToTandC ? 'bg-retoma-blue hover:bg-retoma-blue-light' : 'bg-gray-400'
          } text-white font-bold py-2 px-4 rounded`}
        >
          Agree and Log in
        </button>
      </div>
    </div>
  );
};

export default ConsentDialog;