import React, { useState, useRef, useEffect, useCallback } from 'react';
import api from '../api';
import config from '../config';

const steps = [
  "data_m24_gold",
  "data_m43_value_proposition",
  "data_m51_validation",
  "data_m81_competition",
  "data_m82_marketsize",
  "data_m92_investor",
  "execution_time"
];

const stepDescriptions = [
  "Analyze advancement & impact",
  "Define primary value proposition",
  "Assess innovation maturity",
  "Perform competition and opportunity analysis",
  "Calculate market size",
  "Calculate investability score",
  "Generating report"
];

const Selection = ({
  token,
  file,
  setFile,
  analysisStarted,
  setAnalysisStarted,
  outputPath,
  setOutputPath,
  currentStep,
  setCurrentStep,
  isComplete,
  setIsComplete,
  fetchLatestAccountInfo
}) => {
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handleRemoveFile = (e) => {
    e.stopPropagation();
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const generateExecutionId = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const dateStr = `${year}${month}${day}`;
    const timeStr = `${hours}${minutes}${seconds}`;
    const fullDateTimeStr = `${dateStr}${timeStr}`;

    return `${fullDateTimeStr}-${dateStr}-${timeStr}`;
  };

  const handleAnalyze = async () => {
    if (!file) {
      alert('Please select a file first');
      return;
    }

    setIsAnalyzing(true);
    setError(null);
    setSuccessMessage(null);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const extractResponse = await api.post(`${config.API_BASE_URL}/api/v1/extract_pdf_input`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const { main_content, file_name } = extractResponse.data;
      const accountInfo = JSON.parse(localStorage.getItem('accountInfo'));
      const executionId = generateExecutionId();
      const [fullDateTimeStr, dateStr, timeStr] = executionId.split('-');
      const newOutputPath = `./output/${accountInfo.code}/${executionId}`;

      const analysisData = {
        title: file_name,
        full_text: main_content,
        output_path: newOutputPath,
        code: accountInfo.code,
        execution_id: fullDateTimeStr,
        datetime_str: `${dateStr}-${timeStr}`
      };

      await api.post(`${config.API_BASE_URL}/api/v1/start_analysis`, analysisData);

      setAnalysisStarted(true);
      setSuccessMessage('Analysis started successfully');
      setOutputPath(newOutputPath);
      setCurrentStep(0);
      setIsComplete(false);

      await fetchLatestAccountInfo();

      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (error) {
      console.error('Analysis failed:', error);
      if (error.response) {
        if (error.response.status === 403 || error.response.status === 500) {
          if (error.response.data && typeof error.response.data === 'object' && error.response.data.detail) {
            if (error.response.data.detail.includes("Quota exhausted")) {
              setError('Your quota is exhausted. Please contact support for more information.');
            } else {
              setError(error.response.data.detail);
            }
          } else {
            setError('Your quota may be exhausted. Please try again or contact support.');
          }
        } else if (error.response.status === 401) {
          // Token refresh failed, user will be logged out
          return;
        } else {
          setError('Error starting analysis. Please try again.');
        }
      } else if (error.request) {
        setError('No response received from the server. Please try again.');
      } else {
        setError('Error starting analysis. Please try again.');
      }
    } finally {
      setIsAnalyzing(false);
    }
  };

  const checkProgress = useCallback(async () => {
    if (!outputPath) return;

    for (let i = currentStep; i < steps.length; i++) {
      try {
        const response = await api.get(`${config.API_BASE_URL}/api/v1/check_file_exists`, {
          params: { file_path: `${outputPath}/${steps[i]}.txt` }
        });

        if (!response.data.exists) {
          setCurrentStep(i);
          return;
        }
      } catch (error) {
        console.error('Error checking file existence:', error);
        if (error.response && error.response.status === 401) {
          // Token refresh failed, user will be logged out
          return;
        }
        return;
      }
    }

    setCurrentStep(steps.length);
    setIsComplete(true);
  }, [outputPath, currentStep, setCurrentStep, setIsComplete]);

  useEffect(() => {
    if (analysisStarted && outputPath) {
      const interval = setInterval(checkProgress, 5000);
      return () => clearInterval(interval);
    }
  }, [analysisStarted, outputPath, checkProgress]);

  return (
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-4">Analysis</h2>
      <div className="mb-4">
        <div
          className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center cursor-pointer mb-4"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={() => fileInputRef.current.click()}
        >
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept=".pdf"
            className="hidden"
          />
          {file ? (
            <div className="flex items-center justify-between bg-gray-100 p-2 rounded">
              <span>{file.name}</span>
              <button onClick={handleRemoveFile} className="text-red-500 hover:text-red-700">
                ✕
              </button>
            </div>
          ) : (
            <p>Drag and drop a PDF file here, or click to select a file</p>
          )}
        </div>
        <div className="flex justify-end">
          <button
            onClick={handleAnalyze}
            disabled={isAnalyzing || !file}
            className={`bg-retoma-blue hover:bg-retoma-blue-light text-white font-bold py-2 px-4 rounded ${(isAnalyzing || !file) ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isAnalyzing ? 'Analyzing...' : 'Analyze paper'}
          </button>
        </div>
      </div>
      {error && (
        <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
          {error}
        </div>
      )}
      {successMessage && (
        <div className="mt-4 p-4 bg-green-100 border border-green-400 text-green-700 rounded">
          {successMessage}
        </div>
      )}
      {analysisStarted && (
        <div className="mt-8">
          <h3 className="text-xl font-bold mb-4">Analysis Progress</h3>
          <div className="bg-white shadow-md rounded-lg p-4">
            {steps.map((step, index) => (
              <div key={step} className="mb-2 flex items-center">
                <div className="w-2/3">
                  <p>{stepDescriptions[index]}</p>
                </div>
                <div className="w-1/3">
                  {index < currentStep ? (
                    <span className="text-green-500">Done</span>
                  ) : index === currentStep ? (
                    <span className="text-blue-500">Running</span>
                  ) : (
                    <span className="text-gray-400">Pending</span>
                  )}
                </div>
              </div>
            ))}
          </div>
          {isComplete && (
            <p className="mt-4 text-green-600 font-bold">
              Analysis complete. Find the final report in the Results tab.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default Selection;