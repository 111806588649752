import React from 'react';
import config from '../config';

const Footer = () => {
  return (
    <footer className="fixed bottom-0 left-0 w-full bg-gray-100 text-black text-center py-2">
      <p>
        © 2024 retoma health. All rights reserved. |{' '}
        <a href={config.terms_url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
          Terms and Conditions
        </a>{' '}
        |{' '}
        <a href={config.privacy_policy_url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
          Privacy Policy
        </a>{' '}
        |{' '}
        <a href={config.impressum_url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
          Impressum
        </a>
      </p>
    </footer>
  );
};

export default Footer;