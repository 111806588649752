import React, { useState, useEffect, useCallback } from 'react';
import api from '../api';

const Results = ({ updateQuota }) => {
  const [executions, setExecutions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchExecutions = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get('/api/v1/list_executions');
      setExecutions(response.data.executions || []);
      setError(null);
    } catch (error) {
      console.error('Error fetching executions:', error);
      if (error.response && error.response.status === 401) {
        // Token refresh failed, user will be logged out
        return;
      }
      setError('Failed to fetch results. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchExecutions();
  }, [fetchExecutions]);

  const handleExport = async (pdfPath, paperName) => {
    try {
      const response = await api.get('/api/v1/get_pdf', {
        params: { file_path: pdfPath },
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${paperName}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error exporting PDF:', error);
      alert('Error exporting PDF');
    }
  };

  const handleDelete = async (folderPath) => {
    try {
      const response = await api.post('/api/v1/delete_folder', { folder_path: folderPath });
      if (response.data.quota_updated) {
        updateQuota(response.data.new_quota);
      }
      fetchExecutions();
    } catch (error) {
      console.error('Error deleting folder:', error);
      alert('Error deleting folder');
    }
  };

  const formatDate = (dateString) => {
    try {
      // Assuming the format is "executionId-YYYYMMDD-HHmmss"
      const parts = dateString.split('-');
      if (parts.length === 3) {
        const date = parts[1];
        const time = parts[2];
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6, 8);
        const hour = time.slice(0, 2);
        const minute = time.slice(2, 4);
        const second = time.slice(4, 6);
        return new Date(year, month - 1, day, hour, minute, second).toLocaleString();
      }
    } catch (error) {
      console.error('Error parsing date:', error);
    }
    return 'Invalid Date';
  };

  const isOlderThan2Hours = (dateString) => {
    try {
      const parts = dateString.split('-');
      if (parts.length === 3) {
        const date = parts[1];
        const time = parts[2];
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6, 8);
        const hour = time.slice(0, 2);
        const minute = time.slice(2, 4);
        const second = time.slice(4, 6);
        const executionDate = new Date(year, month - 1, day, hour, minute, second);
        const now = new Date();
        const diffHours = (now - executionDate) / (1000 * 60 * 60);
        return diffHours > 2;
      }
    } catch (error) {
      console.error('Error checking if date is older than 2 hours:', error);
    }
    return false;
  };

  return (
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-4">Results</h2>
      {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}
      {!loading && !error && executions.length === 0 && <p>No results found.</p>}
      {!loading && !error && executions.length > 0 && (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-retoma-blue-dark text-white">
              <tr>
                <th className="py-3 px-4 text-left">Paper title</th>
                <th className="py-3 px-4 text-left">Execution date</th>
                <th className="py-3 px-4 text-left">Status</th>
                <th className="py-3 px-4 text-left">Score</th>
                <th className="py-3 px-4 text-left">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {executions.map((execution) => (
                <tr key={execution.execution_date} className="hover:bg-gray-50">
                  <td className="py-4 px-4">
                    <div className="w-64 break-words">{execution.paper_name}</div>
                  </td>
                  <td className="py-4 px-4 whitespace-nowrap">
                    {formatDate(execution.execution_date)}
                  </td>
                  <td className="py-4 px-4 whitespace-nowrap">{execution.status}</td>
                  <td className="py-4 px-4 whitespace-nowrap">{execution.score}</td>
                  <td className="py-4 px-4 whitespace-nowrap">
                    <div className="flex space-x-2">
                      {execution.status === 'Finished' && (
                        <button
                          onClick={() => handleExport(execution.path, execution.paper_name)}
                          className="bg-retoma-blue hover:bg-retoma-blue-light text-white font-bold py-1 px-2 rounded text-sm"
                        >
                          Export Report
                        </button>
                      )}
                      {(execution.status === 'Failed' || (execution.status === 'Running' && isOlderThan2Hours(execution.execution_date))) && (
                        <button
                          onClick={() => handleDelete(execution.folder_path)}
                          className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded text-sm"
                        >
                          Delete Paper
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Results;